import React from 'react'
import { Link } from 'gatsby'
import {
  FaHandshake,
  FaPenFancy,
  FaGavel,
  FaChartPie,
  FaDollarSign,
  FaBook,
} from 'react-icons/fa'

import Layout from '../components/layout'
import SEO from '../components/seo'

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <section className="header-main">
        <p className="slogan-word">Experienced.</p>
        <p className="slogan-word">Effective.</p>
        <p className="slogan-word">Trustworthy.</p>
        <p className="slogan-word">Professional.</p>
      </section>

      <div className="main-wrapper">
        <section className="about-home-wrapper">
          <h2 className="about-home-title">About Us</h2>
          <div className="about-home">
            <img
              className="about-home-img"
              src="https://res.cloudinary.com/dferdwpfs/image/upload/q_auto,f_auto/v1604969078/plctexas/Ian_and_John.jpg"
              alt="Ian and John"
            />
            <div className="about-home-names">
              <p className="about-home-name">Ian Randolph</p>
              <p className="about-home-name">D. John Hubbard</p>
            </div>
          </div>
          <div className="about-home-blurb">
            <p>
              Premier Legislative Consulting (PLC Texas) is an Austin-based
              government affairs firm that provides a wide variety of services
              to companies, associations, and individuals seeking to influence
              the development of public policy in Texas. PLC Texas can help you
              navigate official government offices, and help build your brand
              with officeholders, policy leaders, Texas state agencies and
              beyond.
            </p>
          </div>
          <div className="about-home-button-wrapper">
            <Link
              className="about-home-button"
              to="/about"
              state={{ home: false }}
            >
              Learn More
            </Link>
          </div>
        </section>
        <section className="home-services">
          <h2>Our Services</h2>
          <p className="home-services-blurb">
            Below is a sample of the services we offer. Please click the Learn
            More button to see a comprehensive list of our services and more
            detailed information.
          </p>

          <div className="home-services-cards">
            <div className="home-services-card">
              <FaHandshake className="home-services-icon" />
              <h3>Lobbying/Advocacy</h3>
            </div>
            <div className="home-services-card">
              <FaChartPie className="home-services-icon" />
              <h3>Strategic Policy Development</h3>
            </div>
            <div className="home-services-card">
              <FaPenFancy className="home-services-icon" />
              <h3>Drafting Legislation & Rules</h3>
            </div>
            <div className="home-services-card">
              <FaGavel className="home-services-icon" />
              <h3>Parliamentary Services</h3>
            </div>
            <div className="home-services-card">
              <FaDollarSign className="home-services-icon" />
              <h3>Government Business Development</h3>
            </div>
            <div className="home-services-card">
              <FaBook className="home-services-icon" />
              <h3>Regulatory Services</h3>
            </div>
          </div>

          <div className="home-services-button-wrapper">
            <Link
              className="home-services-button"
              to="/services"
              state={{ home: false }}
            >
              Learn More
            </Link>
          </div>
        </section>
        <section className="home-clients">
          <h2>Our Clients Include</h2>

          <div className="home-clients-wrapper">
            <div className="client-logo-wrapper">
              <img
                src="https://res.cloudinary.com/dferdwpfs/image/upload/q_auto,f_auto/v1602736220/plctexas/TMAPE.png"
                className="client-logo"
                alt="TMA"
              />
            </div>
            <div className="client-logo-wrapper">
              <img
                src="https://res.cloudinary.com/dferdwpfs/image/upload/q_auto,f_auto/v1602798231/plctexas/TARS.png"
                className="client-logo"
                alt="TARS"
              />
            </div>
            <div className="client-logo-wrapper">
              <img
                src="https://res.cloudinary.com/dferdwpfs/image/upload/q_auto,f_auto/v1602736220/plctexas/TTA.png"
                className="client-logo"
                alt="TTA"
              />
            </div>
            <div className="client-logo-wrapper">
              <img
                src="https://res.cloudinary.com/dferdwpfs/image/upload/q_auto,f_auto/v1602797987/plctexas/SNCF.png"
                className="client-logo"
                alt="SNCF"
              />
            </div>
            <div className="client-logo-wrapper">
              <img
                src="https://res.cloudinary.com/dferdwpfs/image/upload/q_auto,f_auto/v1602736220/plctexas/Safebuses.jpg"
                className="client-logo"
                alt="Safe Buses"
              />
            </div>

            <div className="client-logo-wrapper">
              <div className="lumen-wrapper">
                <img
                  src="https://res.cloudinary.com/dferdwpfs/image/upload/q_auto,f_auto/v1602798032/plctexas/lumen.png"
                  className="client-logo"
                  alt="Lumen"
                />
                <div className="lumen-underscore">
                  <p>Formerly</p>
                  <img
                    src="https://res.cloudinary.com/dferdwpfs/image/upload/q_auto,f_auto/v1602798031/plctexas/Centurylink.png"
                    alt="CenturyLink"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="home-clients-button-wrapper">
            <Link
              className="home-clients-button"
              to="/clients"
              state={{ home: false }}
            >
              See More
            </Link>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default IndexPage
